import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Alert } from 'reactstrap'
import Pagina from '../components/Pagina'
import BackgroundImg from 'gatsby-background-image'

import iconBanner from '../images/icon/RI/icon-banner.svg'
import resultados from '../images/icon/RI/icon-resultados.svg'
import pdfIcon from '../images/icon/RI/pdf.svg'
import logoLwart from '../images/sustentabilidade/icons/logo-lwart.svg'
import './styles/informacoes-financeiras.scss'
import axios from 'axios'

import RenderForm from 'gatsby-plugin-cloudofertas'

const API_URL = process.env.API_URL_INFORMACOES_FINANCEIRAS

const Login = ({ onLogin, onToggleView, onForgotPassword }) => {
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const response = await axios.get(`${API_URL}/users`, {
        params: {
          email: email,
          password: senha
        }
      })

      if (response.status === 200) {
        setMessage({ text: 'Login realizado com sucesso!', type: 'success' })
        onLogin()
      } else {
        setMessage({ text: 'Erro na autenticação. Verifique suas credenciais.', type: 'danger' })
      }
    } catch (error) {
      setMessage({ text: error.response.data.message || 'Erro na autenticação. Verifique suas credenciais.', type: 'danger' })
    }

    setTimeout(() => setMessage({ text: '', type: '' }), 5000)
  }

  return (
    <div className='tela-azul bg-lwart-lightblue2 py-5'>
      <div className='container'>
        <div className="row">
          <div className='col-lg-6 position-relative'>
            <img src={logoLwart} className='w-60 position-absolute position-logo' />
          </div>
          <div className="col-lg-6 login-container py-5">
            <p className='texto-titulo text-start'>Área Restrita para Investidores e Analistas</p>
            {message.text && (
              <Alert color={message.type} className={`w-100 ${message.type === 'success' ? 'alert-success-custom' : ''}`}>
                {message.text}
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <div className="form-group text-white">
                <label htmlFor='email'>E-mail</label>
                <input
                  type='email'
                  id='email'
                  className='form-control'
                  placeholder='Digite seu e-mail'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group text-white">
                <label htmlFor='password'>Senha</label>
                <input
                  type='password'
                  id='password'
                  className='form-control'
                  placeholder='Digite sua senha'
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  required
                  minLength={8}
                />
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <button type='submit' className='btn btn-success'>Acessar</button>
                <p className='text-white font-size-12px mt-3 text-center mr-3'>
                  <span onClick={onForgotPassword} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                    Esqueci minha senha
                  </span>
                </p>
              </div>
              <p className='text-white mt-4 text-center mr-3'>
                Primeiro acesso?{' '}
                <span onClick={onToggleView} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                  Cadastre-se
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const Cadastro = ({ data, onToggleView }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <div className="tela-azul bg-lwart-lightblue2 py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 position-relative">
            <img
              src={logoLwart}
              className={`w-70 ${submitSuccess ? 'logo-show-mobile' : 'logo-hide-mobile'}`}
              alt="Logo Lwart"
            />
          </div>
          <div className="col-lg-6 col-12 p-0">
            {!submitSuccess && (
              <>
                <p className="texto-titulo ml-3">Cadastro</p>
                {data.cadastroFinanceiroForm.formTypeField && (
                  <RenderForm
                    formData={data.cadastroFinanceiroForm}
                    setSuccessSubmit={setSubmitSuccess}
                    placeholder={false}
                    containerStyle="container"
                    rowStyle="row"
                    inputStyle="forms-cadastro"
                    btnContainerStyle="forms-cadastro col-lg-3"
                    btnStyle="btn btn-block"
                    btnName="Enviar"
                  />
                )}
                <p className="text-white mt-4 text-center mr-3">
                  Já tem cadastro?{' '}
                  <span
                    onClick={onToggleView}
                    style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}
                  >
                    Faça Login
                  </span>
                </p>
              </>
            )}

            {submitSuccess && (
              <div className="container-agradecimento">
                <div className="text-center">
                  <p className="text-white agradecimento-texto">
                    Sua solicitação foi recebida e está em análise, <br />Em breve enviaremos no seu e-mail a senha e usuário de acesso. <br />Obrigado!
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const EsqueciMinhaSenha = ({ data, onToggleView }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <div className="tela-azul bg-lwart-lightblue2 py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 position-relative">
            <img
              src={logoLwart}
              className={`w-70 ${submitSuccess ? 'logo-show-mobile' : 'logo-hide-mobile'}`}
              alt="Logo Lwart"
            />
          </div>
          <div className="col-lg-6 col-12 p-0">
            {!submitSuccess && (
              <>
                <p className="texto-titulo ml-3">Renovação de Senha</p>
                {data.renovacaoSenhaForm.formTypeField && (
                  <RenderForm
                    formData={data.renovacaoSenhaForm}
                    setSuccessSubmit={setSubmitSuccess}
                    placeholder={false}
                    containerStyle="container"
                    rowStyle="row"
                    inputStyle="forms-cadastro"
                    btnContainerStyle="forms-cadastro col-lg-3"
                    btnStyle="btn btn-block"
                    btnName="Enviar"
                  />
                )}
                <p className="text-white mt-4 text-center mr-3">
                  Deseja voltar a tela de login?{' '}
                  <span
                    onClick={onToggleView}
                    style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}
                  >
                    Login
                  </span>
                </p>
              </>
            )}

            {submitSuccess && (
              <div className="container-agradecimento">
                <div className="text-center">
                  <p className="text-white agradecimento-texto">
                    Solicitação recebida,<br />Em breve enviaremos no seu e-mail uma nova senha de acesso.<br />Obrigado!
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const InformacoesFinanceiras = ({ data }) => {
  const [view, setView] = useState('login')
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const handleToggleView = () => {
    setView((prev) => (prev === 'login' ? 'cadastro' : 'login'))
  }

  const handleForgotPassword = () => {
    setView('forgot-password')
  }

  const handleLogin = () => {
    setIsAuthenticated(true)
  }

  const renderView = () => {
    if (view === 'login') {
      return <Login onLogin={handleLogin} onToggleView={handleToggleView} onForgotPassword={handleForgotPassword} />
    } else if (view === 'cadastro') {
      return <Cadastro data={data} onToggleView={handleToggleView} />
    } else if (view === 'forgot-password') {
      return <EsqueciMinhaSenha data={data} onToggleView={handleToggleView} />
    }
  }

  return (
    <>
      {!isAuthenticated ? (
        renderView()
      ) : (
        <>
          <Pagina pagina={data.pagina} />
          <Banner banner={data.paralax.childImageSharp.gatsbyImageData} />
          <CentralResultados arquivos={data.arquivos} />
        </>
      )}
      <div className="gradient-blue-to-green"></div>
    </>
  )
}
const Banner = ({ banner }) => {
  const image = getImage(banner)
  const bgImage = convertToBgImage(image)

  return (
    <div>
      {/* Desktop */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-flex d-none"
      >
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100 position-relative">
            <div className='vertical-line position-absolute'></div>
            <div className="col-2 p-0">
              <img src={iconBanner} className='w-90' />
            </div>
            <div className="col-5 p-0">
              <h1 className="text-white">Informações Financeiras</h1>
              {/* <p className="text-white">Pesquise e baixe facilmente nossas Demonstrações de Resultados, Debêntures Emitidos e Framework de Finanças Verdes.</p> */}
            </div>
          </div>
        </div>
      </BackgroundImg>

      {/* Mobile */}
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-md-none d-flex"
      >
        <div className="container h-100 position-relative d-flex align-items-center">
          <div className='vertical-line position-absolute'></div>
          <div>
            <div className='d-flex align-items-center'>
              <img src={iconBanner} className='w-20 mr-3 ml-4' />
              <h1 className="text-white">Informações Financeiras</h1>
            </div>
            <div className='mt-3 ml-4'>
              {/* <p className="text-white">Pesquise e baixe facilmente nossas Demonstrações de Resultados, Debêntures Emitidos e Framework de Finanças Verdes.</p> */}
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}

const CentralResultados = ({ arquivos }) => {
  const [selectedYear, setSelectedYear] = useState('2024')

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const uniqueYears = [...new Set(arquivos.edges.map(edge => edge.node.data.split('/')[2]))]

  return (
    <>
      <div className='container h-100 px-5 py-0 mt-5 mb-5'>
        <div className='d-flex justify-content-end mb-5 custom-select'>
          <select value={selectedYear} onChange={handleYearChange}>
            {uniqueYears.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className='doc-box position-relative'>
          <div className='d-flex'>
            <img src={resultados} className='position-absolute icon-position' />
            <h3 className='text-lwart-blue py-1'>Central de Resultados</h3>
          </div>
          {arquivos.edges
            .filter(edge => edge.node.section === 'Central de Resultados' && edge.node.data.split('/')[2] === selectedYear)
            .length === 0 && (<p className='ml-4 mt-4 mb-3'>Nenhum arquivo encontrado.</p>)
          }
          {arquivos.edges
            .filter(edge => edge.node.section === 'Central de Resultados' && edge.node.data.split('/')[2] === selectedYear)
            .map(edge => (
              <div key={edge.node.nomeArquivo}>
                <div className='row mt-4 my-2'>
                  <div className='col-md-10 col-8 ml-4 d-md-flex align-items-center'>
                    {/* <p className='mr-3'>{edge.node.data}</p> */}
                    <p>{edge.node.nomeArquivo}</p>
                  </div>
                  <div className='col'>
                    <a href={edge.node.arquivos[0].file.url} target="_blank" rel="noopener noreferrer">
                      <img src={pdfIcon} width={30} />
                    </a>
                  </div>
                </div>
                <hr className='w-95' />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

// const Debentures = ({ arquivos }) => {
//   const [selectedYear, setSelectedYear] = useState('2024')

//   const handleYearChange = (e) => {
//     setSelectedYear(e.target.value)
//   }

//   const uniqueYears = [...new Set(arquivos.edges.map(edge => edge.node.data.split('/')[2]))]

//   return (
//     <>
//       <div className='container h-100 px-5 py-0'>
//         <div className='d-flex justify-content-end mb-5 custom-select'>
//           <select value={selectedYear} onChange={handleYearChange}>
//             {uniqueYears.map(year => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//         <div className='doc-box position-relative'>
//           <div className='d-flex'>
//             <img src={debentures} className='position-absolute icon-position' />
//             <h3 className='text-lwart-blue py-1'>Debêntures</h3>
//           </div>
//           {arquivos.edges
//             .filter(edge => edge.node.section === 'Debêntures' && edge.node.data.split('/')[2] === selectedYear)
//             .length === 0 && (<p className='ml-4 mt-4 mb-3'>Nenhum arquivo encontrado.</p>)
//           }
//           {arquivos.edges
//             .filter(edge => edge.node.section === 'Debêntures' && edge.node.data.split('/')[2] === selectedYear)
//             .map(edge => (
//               <div key={edge.node.nomeArquivo}>
//                 <div className='row mt-4 my-2'>
//                   <div className='col-md-10 col-8 ml-4 d-md-flex align-items-center'>
//                     <p className='mr-3'>{edge.node.data}</p>
//                     <p>{edge.node.nomeArquivo}</p>
//                   </div>
//                   <div className='col'>
//                     <a href={edge.node.arquivos[0].file.url} target="_blank" rel="noopener noreferrer">
//                       <img src={pdfIcon} width={30} />
//                     </a>
//                   </div>
//                 </div>
//                 <hr className='w-95' />
//               </div>
//             ))}
//         </div>
//       </div>
//     </>
//   )
// }

// const Framework = ({ arquivos }) => {
//   const [selectedYear, setSelectedYear] = useState('2024')

//   const handleYearChange = (e) => {
//     setSelectedYear(e.target.value)
//   }

//   const uniqueYears = [...new Set(arquivos.edges.map(edge => edge.node.data.split('/')[2]))]

//   return (
//     <>
//       <div className='container h-100 p-5'>
//         <div className='d-flex justify-content-end mb-5 custom-select'>
//           <select value={selectedYear} onChange={handleYearChange}>
//             {uniqueYears.map(year => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//         <div className='doc-box position-relative'>
//           <div className='d-flex'>
//             <img src={framework} className='position-absolute icon-position' />
//             <h3 className='text-lwart-blue py-1'>Framework Finanças Verdes</h3>
//           </div>
//           {arquivos.edges
//             .filter(edge => edge.node.section === 'Framework Finanças Verdes' && edge.node.data.split('/')[2] === selectedYear)
//             .length === 0 && (<p className='ml-4 mt-4 mb-3'>Nenhum arquivo encontrado.</p>)
//           }
//           {arquivos.edges
//             .filter(edge => edge.node.section === 'Framework Finanças Verdes' && edge.node.data.split('/')[2] === selectedYear)
//             .map(edge => (
//               <div key={edge.node.nomeArquivo}>
//                 <div className='row mt-4 my-2'>
//                   <div className='col-md-10 col-8 ml-4 d-md-flex align-items-center'>
//                     <p className='mr-3'>{edge.node.data}</p>
//                     <p>{edge.node.nomeArquivo}</p>
//                   </div>
//                   <div className='col'>
//                     <a href={edge.node.arquivos[0].file.url} target="_blank" rel="noopener noreferrer">
//                       <img src={pdfIcon} width={30} />
//                     </a>
//                   </div>
//                 </div>
//                 <hr className='w-95' />
//               </div>
//             ))}
//         </div>
//       </div>
//     </>
//   )
// }

export default InformacoesFinanceiras

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/informacoes-financeiras/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  arquivos: allContentfulRelacionamentoComInvestidor(filter: {node_locale: {eq: "pt-BR"}}) {
    edges {
      node {
        nomeArquivo
        section
        data
        arquivos {
          file {
            url
          }
        }
      }
    }
  }
  paralax: file(relativePath: {eq: "RI/banner.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }

  cadastroFinanceiroForm: cloudOfertasForm(name: {eq: "CADASTRO_FINANCEIRO"}) {
    id
    formTypeField {
      name
      type
      required
      style
      length
      label
    }
  }
  
  renovacaoSenhaForm: cloudOfertasForm(name: {eq: "RENOVACAO_SENHA"}) {
    id
    formTypeField {
      name
      type
      required
      style
      length
      label
    }
  }
}
`
